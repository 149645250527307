import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/gallery"
import { graphql } from "gatsby"
import InstagramEmbed from "react-instagram-embed"

export default function GalleryPage({ data = {} }) {
  const { barText } = data.takeshape.getTopBar
  const footer = data.takeshape.getContactUsPage
  const [insta, setInsta] = React.useState([])
  const { title, subtitle } = data.takeshape.getGalleryPage

  const getPost = async () => {
    const res = await fetch("https://www.instagram.com/sanalifemassage/?__a=1")
    const data = await res.json()
    setInsta(data.graphql.user.edge_owner_to_timeline_media.edges)
  }
  React.useEffect(() => {
    getPost()
  }, [])

  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Gallery" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 md:mt-16 md:mb-24 mb-10 flex flex-wrap items-start">
        {insta.map(({ node }, index) => (
          <div className="w-full md:w-6/12 lg:w-4/12 p-3" key={index}>
            <InstagramEmbed
              url={`https://instagr.am/p/${node.shortcode}`}
              maxWidth={768}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getGalleryPage {
        title
        subtitle
      }
      getContactUsPage(locale: $locale) {
        title
        subtitle
        content
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
